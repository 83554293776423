import React from "react"


{/*

structure...

<ImageContainer 
  container=""
  src=""
  alt=""
  className=""
  text=""
  classText=""
/>
*/}

const ImageContainer = ({ container, src, alt, className, text, classText }) => {
  let classImg =
    className && className.length !== 0
      ? className
      : "w-full object-cover h-auto"
      
  let classT =
    classText && classText.length !== 0 && text && text.length !== 0
      ? classText
      : ""

  return (
    <div className={`${container}`}>
      <img src={`${src}`} alt={`${alt}`} className={`${classImg}`} />
      {text && text.length !== 0 ? <h3 className={`${classT}`}>{text}</h3> : ""}
    </div>
  )
}

export default ImageContainer
