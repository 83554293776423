/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Footer from "./footer"
import "./layout.css"
//import { HelmetProvider } from "react-helmet-async"
import SEO from "./seo"

const Style = {
  div: {
    all: "w-full",
  },
  layout: {
    all: "w-full",
  },
}

const Layout = ({ children, title, link }) => {
  return (
    <div>
      <SEO
        title={title}
        link={link}/>
      <div className={`${Style.layout.all}`}>
        <Header title={title} description={link} />
        {children}
        <Footer />
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default Layout
