import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import ImageContainer from "./imageContainer"
import logo from "../images/Logo-hellobuild.png"

const style = {
  header: {
    large:
      " absolute top-0 text-6xl font-sans font-bold w-full z-40 lg:mt-2",
  },
  nav: {
    all: "container mx-auto px-5 sm:px-6 md:px-5 lg:px-24 xl:px-16",
    large:
      " flex items-center justify-between flex-wrap px-4 sm:py-2  mt-10 sm:mt-0",
  },
  logo: {
    small: "w-24 px-1",
    large: "flex items-center lg:w-40 lg:pr-4",
  },
  menuMobile: {
    large: "sm:hidden",
  },
  buttonMenu: {
    small:
      "flex items-center px-2 py-1 h-full border  bg-tertiary text-white border-teal-400 focus:outline-none",
  },
  sectionItemsMenu: {
    large:
      "w-full mt-2 block justify-end bg-white sm:mt-0 sm:flex sm:w-7/12 sm:block sm:bg-transparent md:w-8/12 md:pr-2  md:items-center lg:pr-0 lg:mr-3 lg:w-7/12 xl:w-9/12 xl:pr-5  ", // sm:w-6/12 md:w-8/12lg:w-8/12 xl:w-9/12
  },
  containerMenu: {
    large:
      " w-full frk-regular  text-primary text-sm sm:text-lg sm:w-9/12 sm:flex sm:justify-between  md:w-6/12 lg:w-7/12 xl:w-4/12",
  },
  //border-b-2 border-transparent sm:hover:border-red-600 para activar el borde inferior en el hover
  linkMenu: {
    large:
      "block py-4 px-2 font-bold sm:px-0 sm:py-0 sm:inline-block sm:mt-0 active:bg-active active:text-white sm:active:bg-transparent sm:active:text-primary border-b-2 border-transparent sm:hover:border-primary", //
  },
  contactItem: {
    all: "frk-bold bg-primary text-white text-center active:bg-active",
    small: "px-4 py-3 text-sm sm:mt-0  sm:inline-block",
    large: " lg:py-4",
  },
}

const HamburgerMobile = ({ Expanded }) => {
  if (Expanded === "hidden") {
    return (
      <svg
        className="fill-current h-5 w-5 "
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>Menu</title>
        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
      </svg>
    )
  } else {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="fill-current h-3 w-5"
        viewBox="0 0 24 24"
      >
        <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
      </svg>
    )
  }
}

const Header = ({ title }) => {
  const [expHeader, setExpHeader] = useState("hidden")
  const Select = (title, select) => {
    if (title === select)
      return "bg-primary text-white sm:text-primary sm:bg-transparent sm:border-b-2 sm:border-primary"
  }
  return (
    <header className={`${style.header.large} `} style={{ height: "87px" }}>
      <nav className={`${style.nav.all} ${style.nav.large}`}>
        <div className="flex flex-row-reverse">
          <Link to="/">
            <ImageContainer
              container={`${style.logo.small} ${style.logo.large}`}
              src={logo}
              alt="logo"
            />
          </Link>
          <div className={`${style.menuMobile.large} `}>
            <button
              className={`${style.buttonMenu.small}`}
              onClick={() =>
                expHeader === "block"
                  ? setExpHeader("hidden")
                  : setExpHeader("block")
              }
            >
              <HamburgerMobile Expanded={expHeader} />
            </button>
          </div>
        </div>
        <Link
          to="/contact-us"
          className={`${style.contactItem.all} ${style.contactItem.small}${style.contactItem.large} sm:hidden block`}
        >
          Contact Us
        </Link>
        <div
          className={`${style.sectionItemsMenu.large} ${expHeader}  shadow-2xl sm:shadow-none`}
        >
          <div className={`${style.containerMenu.large} `}>
            <Link
              to="/"
              className={`${style.linkMenu.large} ${Select(title, "Home")}`}
            >
              Home
            </Link>
            <Link
              to="/solutions"
              className={`${style.linkMenu.large} ${Select(
                title,
                "Solutions"
              )}`}
            >
              Solutions
            </Link>
            <Link
              to="/about"
              className={`${style.linkMenu.large} ${Select(title, "About")}`}
            >
              About
            </Link>
            {/*<Link to="" className={`${style.linkMenu.large} `}>
              We're Hiring!
            </Link>*/}
          </div>
        </div>
        <Link
          to="/contact-us"
          className={`${style.contactItem.all} ${style.contactItem.small}${style.contactItem.large} hidden sm:block`}
        >
          Contact Us
        </Link>
      </nav>
    </header>
  )
}

Header.propTypes = {
  title: PropTypes.string,
}

Header.defaultProps = {
  title: ``,
}

export default Header
