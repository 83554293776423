import React from "react"
import ImageContainer from "./imageContainer"
//import Pin from "../images/Pin.png"
import linkedin from "../images/linkedin.png"
import twitter from "../images/twitter.png"
import logo from "../images/Logo-hellobuild.png"
import location from "../images/location.png"
import { Link } from "gatsby"
const style = {
  footer: {
    large: "frk-regular bg-primary border-b-8 border-aqua",
  },
  //containerFooter:{
  //    large:'lg:w-10/12 lg:mx-auto text-white px-8 py-20 md:w-full'
  //},
  containerFooter: {
    small: "text-white mx-auto container px-4 py-8 sm:px-6 ",
    large: "lg:px-24 lg:py-16 xl:px-20",
  },
  flexContainer: {
    large: "sm:flex sm:block",
  },
  firstsection: {
    large: "flex-1",
  },
  firstsectionContainer: {
    large:
      "flex justify-between mr-8 w-full sm:mr-0 sm:mb-3   sm:w-11/12 lg:w-7/12 text-lg",
  },
  itemsFirstSection: {
    large: "w-full",
  },
  linkFirstSection: {
    large: "block mb-3",
  },
  secondSection: {
    large: "sm:block sm:flex  sm:w-4/12 lg:w-3/12 lg:mr-16 mt-0  text-lg ",
  },
  secondContainerSection: {
    large: "w-full",
  },
  FollowMeSection:"flex items-center sm:items-start mt-4 sm:mt-1",
  FollowMePar: "w-20 mr-2",
  labelStay: {
    large: "text-lg",
  },
  newsletter: {
    large: "mt-4",
  },
  form: {
    large: "w-full max-w-sm",
  },
  containerForm: {
    large: "flex items-center",
  },
  inputForm: {
    large:
      "appearance-none bg-transparent border-none w-full   py-3 px-5  leading-tight focus:outline-none",
  },
  buttonForm: {
    large: "flex-shrink-0  p-3",
  },
  follow: {
    large: "flex  text-lg  frk-semibold sm:mt-0 ml-2", // lg:mt-10
  },
  border: {
    large: "mt-10 mb-16 opacity-50 box-border",
  },
}
const Footer = () => (
  <footer className={`${style.footer.large}`}>
    <div
      className={`${style.containerFooter.small} ${style.containerFooter.large}`}
    >
      <div className={`${style.flexContainer.large}`}>
        <div className={`${style.firstsection.large}`}>
          <div className={`${style.firstsectionContainer.large}`}>
            <div className={`${style.itemsFirstSection.large}`}>
              <Link
                to="/solutions"
                className={`${style.linkFirstSection.large}`}
              >
                Solutions
              </Link>
              <Link to="/about" className={`${style.linkFirstSection.large}`}>
                {" "}
                About
              </Link>
              {/*<a className={`${style.linkFirstSection.large}`}>We're Hiring!</a>*/}
            </div>
            <div className={`${style.itemsFirstSection.large}`}>
              {/*//link*/}
              <Link
                to="/privacy-policy"
                className={`${style.linkFirstSection.large} `}
              >
                Privacy Policy
              </Link>
              <Link
                to="/contact-us"
                className={`${style.linkFirstSection.large}`}
              >
                {" "}
                Contact Us
              </Link>
            </div>
          </div>
        </div>
        <div className={`${style.secondSection.large}`}>
          {/*<div className={`${style.secondContainerSection.large}`}>
            <div className={`${style.labelStay.large}`}>Stay in touch:</div>
            <div className={`${style.newsletter.large}`}>
              <form className={`${style.form.large}`}>
                <div className={`${style.containerForm.large}`}>
                  <input
                    className={`${style.inputForm.large}`}
                    style={{ backgroundColor: "#0E1C2C", color: "#B3B3B3" }}
                    type="text"
                    placeholder="Your email"
                    aria-label="Full name"
                  />
                  <button
                    style={{ backgroundColor: "#6EF0FA" }}
                    className={`${style.buttonForm.large}`}
                    type="button"
                  >
                    <ImageContainer container="" src={pin} alt="" />
                  </button>
                </div>
              </form>
            </div>
          </div>*/}
          <div className={style.FollowMeSection}>
            <p className={style.FollowMePar}>Follow Us</p>
              <a
                href="https://www.linkedin.com/company/hellobuild/"
                target="_blank"
                rel="noopener noreferrer"
                className={`${style.follow.large}`}
              >
                <ImageContainer container="" src={linkedin} alt="linkedin" />
              </a>
              <a
                href="https://twitter.com/hellobuild"
                target="_blank"
                rel="noopener noreferrer"
                className={`${style.follow.large}`}
              >
                <ImageContainer container="" src={twitter} alt="twitter" />
              </a>
          </div>
        </div>
      </div>
      <div
        style={{ height: "2px", border: "1px solid #979797" }}
        className={`${style.border.large}`}
      ></div>
      <div className="sm:flex sm:justify-between">
        <div>
          <Link to="/" className="w-24 lg:w-40">
            <img src={logo} className="object-cover h-auto" alt="logo" />
          </Link>
        </div>
        <div className="flex  w-11/12 mt-6 sm:mt-0 sm:w-5/12 md:pl-12">
          <ImageContainer
            container=""
            src={location}
            alt="location"
            className="mr-2"
          />
          <div className="text-lg">
            <div className="">MIAMI</div>
            <div className="mt-2" style={{ color: "#B3B3B3" }}>
              2222 Ponce de Leon Blvd <br />
              Miami, FL 33134
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
